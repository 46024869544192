import React, { useState } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import capitalize from 'Modules/capitalize';

const SelectStatus = (props) => {
  const {
    classes,
    selectedItem,
    appointments,
    checkStartTimeAppointment,
    handleAcceptAppointmentSelect,
    handleChangeAppointmentStatus,
    handleFinishAppointmentSelect,
    handleCancelAppointmentSelect,
    handleNoShowAppointmentSelect,
  } = props;

  const [isAcceptedAppointment, setIsAcceptedAppointment] = useState(false);

  const handleOpenSelect = () => {
    if ([2, 5].includes(selectedItem.status_id)) {
      const similarAppointment = appointments.filter((item) => item.start_time === selectedItem.start_time && item.status_id === 1 && item.staff_id === selectedItem.staff_id);
      similarAppointment.length ? setIsAcceptedAppointment(true) : setIsAcceptedAppointment(false);
    }
  };

  return (
    <Select
      name="status"
      value={selectedItem.status_name}
      onOpen={handleOpenSelect}
      onChange={(event) => handleChangeAppointmentStatus(event, event.target.value)}
      className={`appointment-status ${selectedItem.status_name}`}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      inputProps={{
        classes: {
          root: classes.root,
          icon: classes.icon,
        },
      }}
      renderValue={() => (
        <MenuItem value={selectedItem.status_id}>{selectedItem.status_name !== 'accepted' ? i18n.t(`${i18n.t(capitalize(selectedItem.status_name))}`) : i18n.t('Upcoming')}</MenuItem>
      )}
    >
      {![1, 3, 4].includes(selectedItem.status_id) && !isAcceptedAppointment && (
        <MenuItem className="statusItem" value={selectedItem.status_name} onClick={(e) => handleAcceptAppointmentSelect(e, selectedItem.id)}>{i18n.t('Upcoming')}</MenuItem>
      )}
      {selectedItem.status_id === 1 && (checkStartTimeAppointment(selectedItem.date, selectedItem.start_time)) && selectedItem.price.type === 'range' && (
        <MenuItem className="statusItem" value={selectedItem.status_name} onClick={(e) => handleFinishAppointmentSelect(e, selectedItem.id)}>{i18n.t('Finished')}</MenuItem>
      )}
      {selectedItem.status_id !== 3 && selectedItem.status_id !== 4 && (
        <MenuItem className="statusItem" value={selectedItem.status_name} onClick={(e) => handleCancelAppointmentSelect(e, selectedItem.id)}>{i18n.t('Cancelled')}</MenuItem>
      )}
      {![1, 2, 3, 4, 5].includes(selectedItem.status_id) && (
        <MenuItem className="statusItem" value={selectedItem.status_name}>{i18n.t('Pending')}</MenuItem>
      )}
      {![1, 2, 3, 4, 5].includes(selectedItem.status_id) && (
        <MenuItem className="statusItem" value={selectedItem.status_name}>{i18n.t('Request')}</MenuItem>
      )}
      {selectedItem.status_id !== 6 && (
        <MenuItem className="statusItem" value={selectedItem.status_name} onClick={(e) => handleNoShowAppointmentSelect(e, selectedItem.id)}>{i18n.t('NoShow')}</MenuItem>
      )}
    </Select>
  );
};

SelectStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  appointments: PropTypes.array.isRequired,
  selectedItem: PropTypes.object.isRequired,
  checkStartTimeAppointment: PropTypes.func.isRequired,
  handleAcceptAppointmentSelect: PropTypes.func.isRequired,
  handleChangeAppointmentStatus: PropTypes.func.isRequired,
  handleFinishAppointmentSelect: PropTypes.func.isRequired,
  handleCancelAppointmentSelect: PropTypes.func.isRequired,
  handleNoShowAppointmentSelect: PropTypes.func.isRequired,
};

export default SelectStatus;
