import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Header({ appointment, onClose }) {
  const { t } = useTranslation();

  const statuses = {
    accepted: {
      className: 'status-color-upcoming',
      text: t('Upcoming'),
    },
    pending: {
      className: 'status-color-pending',
      text: t('Pending'),
    },
    cancelled: {
      className: 'status-color-cancel',
      text: t('Cancelled'),
    },
    finished: {
      className: 'status-color-finished',
      text: t('Finished'),
    },
    request: {
      className: 'status-color-request',
      text: t('Request'),
    },
    noShow: {
      className: 'status-color-noShow',
      text: t('NoShow'),
    },
  };
  return (
    <div>
      <div className="title-content">
        <h3 className="title">
          {appointment.id ? t('AppointmentDetails') : t('NewAppointment')}
        </h3>
        <CloseIcon className="close-icon" onClick={onClose} />
      </div>
      {appointment.id && (
        <div className="details-content">
          <div className="status-content">
            <div>
              <div className="detail-text">
                ID: {appointment.id}
              </div>
            </div>
            <div className={`${statuses[appointment.status_name].className} status`}>
              {statuses[appointment.status_name].text}
            </div>
          </div>
          <div className="detail-text source">
            <span>
              {`${t('Source')}: `}
            </span>
            <span>
              {appointment.from_widget ? ` ${t('BookingLink')}`
                : ` ${t('Calendar')}`}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

Header.propTypes = {
  appointment: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Header;
