import { createAction } from 'redux-actions';

export const storeAppointmentRequest = createAction('STORE_APPOINTMENT_REQUEST');
export const storeAppointmentSuccess = createAction('STORE_APPOINTMENT_SUCCESS');
export const storeAppointmentError = createAction('STORE_APPOINTMENT_ERROR');

export const validAppointmentRequest = createAction('VALID_APPOINTMENT_REQUEST');
export const validAppointmentSuccess = createAction('VALID_APPOINTMENT_SUCCESS');
export const validAppointmentError = createAction('VALID_APPOINTMENT_ERROR');

export const updateAppointmentRequest = createAction('UPDATE_APPOINTMENT_REQUEST');
export const updateAppointmentSuccess = createAction('UPDATE_APPOINTMENT_SUCCESS');
export const updateAppointmentError = createAction('UPDATE_APPOINTMENT_ERROR');

export const deleteAppointmentRequest = createAction('DELETE_APPOINTMENT_REQUEST');
export const deleteAppointmentSuccess = createAction('DELETE_APPOINTMENT_SUCCESS');
export const deleteAppointmentError = createAction('DELETE_APPOINTMENT_ERROR');

export const getAppointmentsRequest = createAction('GET_APPOINTMENTS_REQUEST');
export const getAppointmentsSuccess = createAction('GET_APPOINTMENTS_SUCCESS');
export const getAppointmentsError = createAction('GET_APPOINTMENTS_ERROR');

export const getAppointmentByIdRequest = createAction('GET_APPOINTMENT_BY_ID_REQUEST');
export const getAppointmentByIdSuccess = createAction('GET_APPOINTMENT_BY_ID_SUCCESS');
export const getAppointmentByIdError = createAction('GET_APPOINTMENT_BY_ID_ERROR');

export const acceptRequest = createAction('ACCEPT_REQUEST');
export const acceptSuccess = createAction('ACCEPT_SUCCESS');
export const acceptError = createAction('ACCEPT_ERROR');

export const cancelRequest = createAction('CANCEL_REQUEST');
export const cancelSuccess = createAction('CANCEL_SUCCESS');
export const cancelError = createAction('CANCEL_ERROR');

export const finishRequest = createAction('FINISH_REQUEST');
export const finishSuccess = createAction('FINISH_SUCCESS');
export const finishError = createAction('FINISH_ERROR');

export const noShowRequest = createAction('NO_SHOW_REQUEST');
export const noShowSuccess = createAction('NO_SHOW_SUCCESS');
export const noShowError = createAction('NO_SHOW_ERROR');

export const getAllStatusesRequest = createAction('GET_ALL_STATUSES_REQUEST');
export const getAllStatusesSuccess = createAction('GET_ALL_STATUSES_SUCCESS');
export const getAllStatusesError = createAction('GET_ALL_STATUSES_ERROR');

export const getGeneralAppointmentsRequest = createAction('GET_GENERAL_APPOINTMENTS_REQUEST');
export const getGeneralAppointmentsSuccess = createAction('GET_GENERAL_APPOINTMENTS_SUCCESS');
export const getGeneralAppointmentsError = createAction('GET_GENERAL_APPOINTMENTS_ERROR');

export const getUserRoleInAppointmentRequest = createAction('GET_USER_ROLE_IN_APPOINTMENT_REQUEST');
export const getUserRoleInAppointmentSuccess = createAction('GET_USER_ROLE_IN_APPOINTMENT_SUCCESS');
export const getUserRoleInAppointmentError = createAction('GET_USER_ROLE_IN_APPOINTMENT_ERROR');

export const filterStaffAppointmentsRequest = createAction('FILTER_STAFF_APPOINTMENTS_REQUEST');
export const filterStaffAppointmentsSuccess = createAction('FILTER_STAFF_APPOINTMENTS_SUCCESS');
export const filterStaffAppointmentsError = createAction('FILTER_STAFF_APPOINTMENTS_ERROR');

export const filterAllAppointmentsRequest = createAction('FILTER_ALL_APPOINTMENTS_REQUEST');
export const filterAllAppointmentsSuccess = createAction('FILTER_ALL_APPOINTMENTS_SUCCESS');
export const filterAllAppointmentsError = createAction('FILTER_ALL_APPOINTMENTS_ERROR');

export const checkAppointmentsByServiceIdRequest = createAction('CHECK_APPOINTMENTS_BY_SERVICE_ID_REQUEST');
export const checkAppointmentsByServiceIdSuccess = createAction('CHECK_APPOINTMENTS_BY_SERVICE_ID_SUCCESS');
export const checkAppointmentsByServiceIdError = createAction('CHECK_APPOINTMENTS_BY_SERVICE_ID_ERROR');

export const checkCompanyDayAppointmentsRequest = createAction('CHECK_COMPANY_DAY_APPOINTMENTS_REQUEST');
export const checkCompanyDayAppointmentsSuccess = createAction('CHECK_COMPANY_DAY_APPOINTMENTS_SUCCESS');
export const checkCompanyDayAppointmentsError = createAction('CHECK_COMPANY_DAY_APPOINTMENTS_ERROR');

export const cancelCompanyDayAppointmentsRequest = createAction('CANCEL_COMPANY_DAY_APPOINTMENTS_REQUEST');
export const cancelCompanyDayAppointmentsSuccess = createAction('CANCEL_COMPANY_DAY_APPOINTMENTS_SUCCESS');
export const cancelCompanyDayAppointmentsError = createAction('CANCEL_COMPANY_DAY_APPOINTMENTS_ERROR');

export const updateAppointmentsReminderRequest = createAction('UPDATE_APPOINTMENTS_REMINDER_REQUEST');
export const updateAppointmentsReminderSuccess = createAction('UPDATE_APPOINTMENTS_REMINDER_SUCCESS');
export const updateAppointmentsReminderError = createAction('UPDATE_APPOINTMENTS_REMINDER_ERROR');

export const updateAppointmentsThankYouMessageRequest = createAction('UPDATE_APPOINTMENTS_THANK_YOU_MESSAGE_REQUEST');
export const updateAppointmentsThankYouMessageSuccess = createAction('UPDATE_APPOINTMENTS_THANK_YOU_MESSAGE_SUCCESS');
export const updateAppointmentsThankYouMessageError = createAction('UPDATE_APPOINTMENTS_THANK_YOU_MESSAGE_ERROR');

export const filterAllIndividualAppointmentsRequest = createAction('FILTER_ALL_INDIVIDUAL_APPOINTMENTS_REQUEST');
export const filterAllIndividualAppointmentsSuccess = createAction('FILTER_ALL_INDIVIDUAL_APPOINTMENTS_SUCCESS');
export const filterAllIndividualAppointmentsError = createAction('FILTER_ALL_INDIVIDUAL_APPOINTMENTS_ERROR');

export const checkIndividualUserIndustryDayAppointmentsRequest = createAction('CHECK_INDIVIDUAL_USER_INDUSTRY_DAY_APPOINTMENTS_REQUEST');
export const checkIndividualUserIndustryDayAppointmentsSuccess = createAction('CHECK_INDIVIDUAL_USER_INDUSTRY_DAY_APPOINTMENTS_SUCCESS');
export const checkIndividualUserIndustryDayAppointmentsError = createAction('CHECK_INDIVIDUAL_USER_INDUSTRY_DAY_APPOINTMENTS_ERROR');

export const cancelIndividualUserIndustryDayAppointmentsRequest = createAction('CANCEL_INDIVIDUAL_USER_INDUSTRY_DAY_APPOINTMENTS_REQUEST');
export const cancelIndividualUserIndustryDayAppointmentsSuccess = createAction('CANCEL_INDIVIDUAL_USER_INDUSTRY_DAY_APPOINTMENTS_SUCCESS');
export const cancelIndividualUserIndustryDayAppointmentsError = createAction('CANCEL_INDIVIDUAL_USER_INDUSTRY_DAY_APPOINTMENTS_ERROR');

export const getIndividualAppointmentsStatisticsRequest = createAction('GET_INDIVIDUAL_APPOINTMENTS_STATISTICS_REQUEST');
export const getIndividualAppointmentsStatisticsSuccess = createAction('GET_INDIVIDUAL_APPOINTMENTS_STATISTICS_SUCCESS');
export const getIndividualAppointmentsStatisticsError = createAction('GET_INDIVIDUAL_APPOINTMENTS_STATISTICS_ERROR');

export const getAppointmentsStatisticsRequest = createAction('GET_APPOINTMENTS_STATISTICS_REQUEST');
export const getAppointmentsStatisticsSuccess = createAction('GET_APPOINTMENTS_STATISTICS_SUCCESS');
export const getAppointmentsStatisticsError = createAction('GET_APPOINTMENTS_STATISTICS_ERROR');

export const getCalendarAppointmentsRequest = createAction('GET_CALENDAR_APPOINTMENTS_REQUEST');
export const getCalendarAppointmentsSuccess = createAction('GET_CALENDAR_APPOINTMENTS_SUCCESS');
export const getCalendarAppointmentsError = createAction('GET_CALENDAR_APPOINTMENTS_ERROR');

export const filterAllMyAppointmentsRequest = createAction('FILTER_ALL_MY_APPOINTMENTS_REQUEST');
export const filterAllMyAppointmentsSuccess = createAction('FILTER_ALL_MY_APPOINTMENTS_SUCCESS');
export const filterAllMyAppointmentsError = createAction('FILTER_ALL_MY_APPOINTMENTS_ERROR');

export const createTimeOffRequest = createAction('CREATE_TIME_OFF_REQUEST');
export const createTimeOffSuccess = createAction('CREATE_TIME_OFF_SUCCESS');
export const createTimeOffError = createAction('CREATE_TIME_OFF_ERROR');

export const updateTimeOffRequest = createAction('UPDATE_TIME_OFF_REQUEST');
export const updateTimeOffSuccess = createAction('UPDATE_TIME_OFF_SUCCESS');
export const updateTimeOffError = createAction('UPDATE_TIME_OFF_ERROR');
