import DialogContent from '@material-ui/core/DialogContent';
import { Box } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import deleteIcon from '../../../../../../../assets/icons/modal-delete.svg';
import warningIcon from '../../../../../../../assets/icons/modal-warning.svg';
import styles from '../../../Service/styles.module.scss';

function AppointmentActionModal({
  open,
  onClose,
  handleDelete,
  handleCancel,
  type,
  appointmentCancelLoading,
  appointmentDeleteLoading,
  fromTimeOff,
}) {
  const { t } = useTranslation();
  const isCancel = type === 'cancel';

  // Style
  const useStyles = makeStyles(() => ({
    dialogPaper: {
      minWidth: '356px',
      maxWidth: '356px',
    },
    dialogActionsRoot: {
      padding: '16px 24px 24px',
      justifyContent: 'space-between',
    },
    cancelBtn: {
      color: '#A4A4A4',
      font: 'normal 600 14px/19px Nunito Sans, sans-serif',
      border: '1px solid #A4A4A4',
      borderRadius: '4px',
      padding: '7px 50px',
      maxWidth: '150px',
      width: '100%',
      height: 33,
    },
    deleteBtn: {
      backgroundColor: '#F12B2C',
      color: '#FFFFFF',
      font: 'normal 600 14px/19px Nunito Sans, sans-serif',
      borderRadius: '4px',
      padding: '7px 50px',
      maxWidth: '150px',
      width: '100%',
    },
  }));
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-dialog"
      className={styles.serviceModal}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={styles.content}>
        <Box className={styles.imgBox}>
          <img src={isCancel ? warningIcon : deleteIcon} alt="delete" />
        </Box>
        <DialogContentText className={styles.contentText}>
          {isCancel ? t('AppointmentCancelModalText') : (fromTimeOff ? t('TimeOffDeleteModalText') : t('AppointmentDeleteModalText'))}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button
          onClick={onClose}
          color="primary"
          className={classes.cancelBtn}
          autoFocus
        >
          {isCancel ? t('No') : t('Cancel')}
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={isCancel ? handleCancel : handleDelete}
          style={{
            color: 'white', backgroundColor: isCancel ? '#A4A4A4' : '#F12B2C', width: '100%', height: 33,
          }}
        >
          {(appointmentCancelLoading || appointmentDeleteLoading) && <CircularProgress color="white" size={20} />}
          {(!appointmentCancelLoading || !appointmentDeleteLoading) && isCancel ? t('Cancel') : t('Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AppointmentActionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  appointmentDeleteLoading: PropTypes.bool.isRequired,
  appointmentCancelLoading: PropTypes.bool.isRequired,
  fromTimeOff: PropTypes.bool,
};

AppointmentActionModal.defaultProps = {
  fromTimeOff: false,
};
export default AppointmentActionModal;
