import {
  CircularProgress,
  Drawer,
  TextField,
  Button,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { useTranslation } from 'react-i18next';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { useDispatch, useSelector } from 'react-redux';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import moment from 'moment';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Header from './Header';
import getServicePrice from '../../../../../../Modules/getServicePrice';
import serviceDuration from '../../../../../../Modules/serviceDuration';
import './style.scss';
import getCurrencyIcon from '../../../../../../Modules/getCurrencyIcon';
import DeleteButton from '../../../../../../MaterialComponents/deleteButton';
import {
  acceptRequest,
  cancelRequest,
  deleteAppointmentRequest,
  finishRequest,
} from '../../../../../../redux/appointment/actions';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import AppointmentActionModal from './ActionModal';

function AppointmentDetailsDrawer({
  isOpen,
  onClose,
  appointment,
  snackBarAlert,
  appointments,
  setAppointments,
  checkedItems,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { companyGot } = useSelector((state) => state.company);
  const { userAccount } = useSelector((state) => state.account);

  const {
    isFinishSuccess,
    isDeleteAppointmentSuccess,
    isAcceptSuccess,
    isCancelSuccess,
  } = useSelector((state) => state.appointment);

  const prevIsDeleteAppointmentSuccess = usePrevious(isDeleteAppointmentSuccess);
  const prevIsFinishSuccess = usePrevious(isFinishSuccess);
  const prevIsCancelSuccess = usePrevious(isCancelSuccess);
  const prevIsAcceptSuccess = usePrevious(isAcceptSuccess);

  const {
    id,
    customer_name,
    customer_email,
    customer_phone_number,
    staff_name,
    profession_name,
    staff_number,
    category_name,
    service_name,
    price,
    status_name,
    duration,
    date,
    start_time,
    description,
    appointment_date,
  } = appointment;

  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const [appointmentDeleteLoading, setAppointmentDeleteLoading] = useState(false);
  const [appointmentAcceptLoading, setAppointmentAcceptLoading] = useState(false);
  const [appointmentCancelLoading, setAppointmentCancelLoading] = useState(false);
  const [finalPrice, setFinalPrice] = useState('');
  const [isOpenActionModal, setIsOpenActionModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const [error, setError] = useState(false);
  const now = moment();
  const appointmentTime = moment(`${date || appointment_date} ${start_time}`);

  useEffect(() => {
    if (prevIsFinishSuccess === false && isFinishSuccess) {
      snackBarAlert(true, t('Appointment is finish'), 'success');
      const appointmentsCopy = [...appointments];
      const index = appointmentsCopy.findIndex((i) => i.id === appointment.id);
      appointmentsCopy[index].price = price.type === 'range' ? finalPrice : price;
      appointmentsCopy[index].appointment_price = finalPrice;
      appointmentsCopy[index].is_accept_allowed = false;
      appointmentsCopy[index].status_name = 'finished';
      appointmentsCopy[index].status_id = 4;
      appointmentsCopy[index].headerBgColor = '#98D3FC';
      appointmentsCopy[index].borderColor = '#98D3FC';
      appointmentsCopy[index].backgroundColor = '#98D3FC';
      setAppointments(appointmentsCopy);
      setAppointmentLoading(false);
      onClose();
    }
  }, [isFinishSuccess]);

  // Appointment was canceled
  useEffect(() => {
    if (prevIsCancelSuccess === false && isCancelSuccess) {
      const appointmentCopy = [...appointments];
      const appointmentIndex = appointmentCopy.findIndex((item) => item.id === appointment.id);
      if (checkedItems.status.includes(3) && appointmentCopy.length) {
        appointmentCopy[appointmentIndex].status_id = 3;
        appointmentCopy[appointmentIndex].is_accept_allowed = false;
        appointmentCopy[appointmentIndex].status_name = 'cancelled';
      } else {
        appointmentCopy.splice(appointmentIndex, 1);
      }
      setAppointments(appointmentCopy);
      onClose();
      snackBarAlert(true, t('AppointmentCanceled'), 'info');
    }
  }, [isCancelSuccess]);

  useEffect(() => {
    if (prevIsDeleteAppointmentSuccess === false && isDeleteAppointmentSuccess) {
      snackBarAlert(true, t('AppointmentDeleted'), 'success');
      setAppointmentDeleteLoading(false);
      const appointmentsCopy = [...appointments];
      const index = appointmentsCopy.findIndex((i) => i.id === appointment.id);
      appointmentsCopy.splice(index, 1);
      setAppointments(appointmentsCopy);
      onClose();
    }
  }, [isDeleteAppointmentSuccess]);

  useEffect(() => {
    if (prevIsAcceptSuccess === false && isAcceptSuccess) {
      snackBarAlert(true, t('AppointmentAccepted'), 'success');
      const appointmentsCopy = [...appointments];
      const index = appointmentsCopy.findIndex((i) => i.id === appointment.id);
      if (checkedItems.status.includes(1)) {
        appointmentsCopy[index].is_accept_allowed = true;
        appointmentsCopy[index].status_name = 'accepted';
        appointmentsCopy[index].status_id = 1;
        appointmentsCopy[index].headerBgColor = '#B1DCAF';
        appointmentsCopy[index].borderColor = '#B1DCAF';
        appointmentsCopy[index].backgroundColor = '#B1DCAF';
      } else {
        appointmentsCopy.splice(index, 1);
      }

      setAppointments(appointmentsCopy);
      setAppointmentAcceptLoading(false);
      onClose();
    }
  }, [isAcceptSuccess]);

  const renderTime = (start_time, duration = 0) => moment(`${moment().format('YYYY-MM-DD')} ${start_time}`)
    .locale('en')
    .add(duration, 'minutes')
    .format(userAccount.time_format === '12' ? 'hh:mm a' : 'HH:mm');

  const finish = () => {
    if (price.type === 'range' && !finalPrice && finalPrice.length === 0) {
      setError(true);
      return false;
    }

    setError(false);
    setAppointmentLoading(true);
    dispatch(finishRequest({ id, price: price.type === 'range' ? finalPrice : price.price.from }));
  };

  const deleteApp = () => {
    setAppointmentDeleteLoading(true);
    dispatch(deleteAppointmentRequest({ id: appointment.id }));
  };

  const accept = () => {
    setAppointmentAcceptLoading(true);
    const { id } = appointment;
    dispatch(acceptRequest(id));
  };

  const cancelApp = () => {
    setAppointmentCancelLoading(true);
    const { id } = appointment;
    dispatch(cancelRequest({ id }));
  };

  const generatePrice = (item) => {
    if (item.status_id !== 4) {
      return item.price ? getServicePrice(item.price.type, item.price.price, companyGot) : '-';
    } else {
      return `${item.appointment_price} ${getCurrencyIcon(companyGot.currency)}`;
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      className="appointment-drawer"
      // disableBackdropClick
      PaperProps={{
        style: {
          height: 'calc(100% - 64px)',
          top: 64,
          maxWidth: '563px',
          width: '100%',
        },
      }}
      id="appointment-details-drawer"
    >
      <div className="drawer-content">
        <div>
          <Header appointment={appointment} onClose={onClose} />
          <div className="input-content details">
            <div className="input-title-box start">
              <PersonOutlineOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
              <div>
                <span className="input-title">
                  {t('Customer')}
                </span>
                <div className="info-text">
                  {customer_name || t('WalkIn')}
                </div>
                {customer_phone_number && (
                  <div className="info-text">
                    {customer_phone_number}
                  </div>
                )}
                {customer_email && (
                  <div className="info-text">
                    {customer_email}
                  </div>
                )}
              </div>
            </div>
          </div>
          {companyGot.is_staff_based && (
            <div className="input-content details">
              <div className="input-title-box start">
                <AssignmentIndOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
                <div>
                  <span className="input-title">{t('Staff')}</span>
                  <div className="info-text">{staff_name}</div>
                  <div className="info-text">{profession_name}</div>
                  {staff_number && <div className="info-text">{staff_number}</div>}
                </div>
              </div>
            </div>
          )}
          <div className="input-content details">
            <div className="input-title-box start">
              <ListAltOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
              <div>
                <span className="input-title">
                  {t('Service')}
                </span>
                <div className="info-text">
                  {`${category_name} ${service_name}`}
                </div>
                <div className="info-text">
                  <div className="service-info detail">
                    <div className="text">
                      <span>
                        {generatePrice(appointment)}
                        {/* {getServicePrice(price.type, price.price, companyGot)} */}
                      </span>
                      <span style={{ marginLeft: 10 }}>
                        {serviceDuration(duration, t('H'), t('Min'))}
                      </span>
                    </div>
                    <AccessTimeIcon />
                  </div>
                </div>
              </div>
            </div>
            {price.type === 'range' && status_name === 'accepted' && now.isAfter(appointmentTime) && (
              <div className="final-price">
                <div className="text">
                  {t('FinalPriceText')}
                </div>
                <div>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={error}
                  >
                    <TextField
                      type="number"
                      size="small"
                      color="secondary"
                      className="final-price-input"
                      placeholder={t('Price')}
                      error={error}
                      InputProps={{
                        endAdornment: getCurrencyIcon(companyGot.currency),
                      }}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setFinalPrice(e.target.value)}
                    />

                    {error && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {t('Price') + t('IsRequired')}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </div>
              </div>
            )}
          </div>
          <div className="input-content details">
            <div className="input-title-box start">
              <DateRangeOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
              <div>
                <span className="input-title">
                  {t('DateAndTime')}
                </span>
                <div className="info-text">
                  {moment(date)
                    .locale(`${i18n.language}${i18n.language === 'hy' ? '-am' : ''}`)
                    .format('ddd, D MMM YYYY')}
                </div>
                <div className="info-text">
                  {`${renderTime(
                    start_time,
                  )} - ${renderTime(
                    start_time,
                    duration,
                  )}`}
                </div>
              </div>
            </div>
          </div>
          {description && (
            <div className="input-content details">
              <div className="input-title-box start">
                <TextsmsOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
                <div>
                  <span className="input-title">
                    {t('Note')}
                  </span>
                  <div>
                    {description}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="buttons-box content-between" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="btn">
              <DeleteButton
                variant="outlined"
                loading={appointmentDeleteLoading}
                startIcon={<DeleteIcon color="error" />}
                onClick={() => {
                  setActionType('delete');
                  setIsOpenActionModal(true);
                }}
              >
                {appointmentDeleteLoading && <CircularProgress color="white" size={20} />}
                {!appointmentDeleteLoading && t('Delete') }
              </DeleteButton>
            </div>

            <div className="right-button-content">
              {status_name === 'accepted' && price.type === 'range' && now.isAfter(appointmentTime) && (
                <div className="create-btn">
                  <Button
                    size="small"
                    onClick={finish}
                    data-cy="save"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={price.type === 'range' ? finalPrice.length === 0 : false}
                    loading={appointmentLoading}
                    style={{
                      cursor: 'pointer',
                      borderRadius: 4,
                      backgroundColor: '#0282DA',
                      padding: '8px 16px',
                      font: 'normal 400 14px/19px Nunito Sans, sans-serif',
                      color: '#FFFFFF',
                      width: '100%',
                      height: '100%',
                      opacity: price.type === 'range' ? finalPrice.length === 0 ? '0.5' : '1' : '1',
                    }}
                  >
                    {appointmentLoading && <CircularProgress color="white" size={20} />}
                    { t('FinishAppointment') }
                  </Button>
                </div>
              )}

              {['pending', 'accepted', 'request'].includes(status_name) && (
                <div className="create-btn">
                  <Button
                    onClick={() => {
                      setActionType('cancel');
                      setIsOpenActionModal(true);
                    }}
                    data-cy="cancel"
                    type="cancel"
                    size="small"
                    style={{
                      borderRadius: 4,
                      border: '1px solid #A4A4A4',
                      backgroundColor: '#FFFFFF',
                      padding: '6px 28px',
                      font: 'normal 400 14px/19px Nunito Sans, sans-serif',
                      color: '#A4A4A4',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {appointmentCancelLoading && <CircularProgress color="white" size={20} />}
                    { t('CancelAppointment') }
                  </Button>
                </div>
              )}

              {['pending', 'request'].includes(status_name) && (
                <div className="create-btn">
                  <Button
                    size="small"
                    onClick={() => accept(appointment.id)}
                    data-cy="save"
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={appointmentAcceptLoading}
                    style={{
                      cursor: 'pointer',
                      borderRadius: 4,
                      backgroundColor: '#0282DA',
                      padding: '8px 16px',
                      font: 'normal 400 14px/19px Nunito Sans, sans-serif',
                      color: '#FFFFFF',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {appointmentAcceptLoading && <CircularProgress color="white" size={20} />}
                    {!appointmentAcceptLoading && t('Accept') }
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <AppointmentActionModal
        type={actionType}
        open={isOpenActionModal}
        onClose={() => setIsOpenActionModal(false)}
        handleDelete={deleteApp}
        handleCancel={cancelApp}
        appointmentCancelLoading={appointmentCancelLoading}
        appointmentDeleteLoading={appointmentDeleteLoading}
      />
    </Drawer>
  );
}

AppointmentDetailsDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.object.isRequired,
  appointment: PropTypes.object.isRequired,
  snackBarAlert: PropTypes.func.isRequired,
  appointments: PropTypes.array.isRequired,
  setAppointments: PropTypes.func.isRequired,
  checkedItems: PropTypes.array.isRequired,
};

export default AppointmentDetailsDrawer;
