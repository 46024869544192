import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { withStyles } from '@material-ui/core/styles';
import {
  Box, FormControl, IconButton, MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import deleteIcon from 'assets/icons/delete.svg';
import editIcon from 'assets/icons/edit.svg';
import i18n from 'i18next';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import capitalize from 'Modules/capitalize';
import { useSelector } from 'react-redux';
import serviceDuration from 'Modules/serviceDuration';
import getServicePrice from 'Modules/getServicePrice';
import SelectStatus from '../SelectStatus';
import getInitials from '../../../../../../Modules/getInitials';
import DefaultProfileImage from '../../../../../../assets/img/default-profile.png';
import getCurrencyIcon from '../../../../../../Modules/getCurrencyIcon';
import getColorsByAppointmentStatus from '../../../../../../Modules/getColorsByAppointmentStatus';

const TableBodyCell = withStyles(() => ({
  root: {
    color: '#393939',
    font: 'normal 500 14px/19px Nunito Sans, sans-serif',
  },
}))(TableCell);

const TODAY = moment().format('YYYY-MM-DD');
const TOMORROW = moment()
  .add(1, 'days')
  .format('YYYY-MM-DD');

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableCell-body': {
      padding: '7px 16px',
    },
  },
  paginationTable: {
    marginTop: 15,
  },
  itemsImg: {
    width: 30,
    height: 30,
    verticalAlign: 'middle',
    marginRight: 10,
    borderRadius: '50%',
    objectFit: 'cover',
  },
  avatarAppointments: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  avatarLettersAppointments: {
    width: 30,
    height: 30,
    marginRight: 10,
    fontSize: 13,
  },
  tableCell: {
    cursor: 'pointer',
  },
  formControl: {
    minWidth: 120,
  },
  root: {
    width: 100,
  },
  icon: {
    fill: 'white',
  },
});

function Body({
  appointments,
  order,
  orderBy,
  checkStartTimeAppointment,
  handleCancelAppointmentSelect,
  handleFinishAppointmentSelect,
  handleChangeAppointmentStatus,
  handleAcceptAppointmentSelect,
  handleNoShowAppointmentSelect,
  showConfirmDelete,
  handleOpenUpdateModal,
}) {
  const { companyGot } = useSelector((state) => state.company);
  const { userSettings } = useSelector((state) => state.account);

  const { t } = useTranslation();

  const classes = useStyles();

  const sortColumnName = (columnName, a) => {
    let sortBy;

    switch (columnName) {
      case 'price': {
        sortBy = a.price.price.from;
        break;
      }
      case 'start_time': {
        sortBy = moment(`${a.date} ${a.start_time}`).format('HH:mm');
        break;
      }
      default: {
        sortBy = '';
      }
    }

    return sortBy;
  };

  function descendingComparator(a, b, orderBy) {
    const sortByA = sortColumnName(orderBy, a);
    const sortByB = sortColumnName(orderBy, b);

    const x = typeof b[orderBy] === 'object' ? sortByA : a[orderBy];
    const y = typeof b[orderBy] === 'object' ? sortByB : b[orderBy];

    if (y < x) {
      return -1;
    }
    if (y > x) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function generatePrice(item) {
    if (item.status_id !== 4) {
      return item.price ? getServicePrice(item.price.type, item.price.price, companyGot) : '-';
    } else {
      return `${item.appointment_price} ${getCurrencyIcon(companyGot.currency)}`;
    }
  }

  return (
    <TableBody>
      {stableSort(appointments, getComparator(order, orderBy)).map((item, index) => (
        <TableRow style={{ cursor: 'pointer' }} key={`${index}-${item.id}`}>
          <TableBodyCell component="th" scope="row" onClick={(e) => handleOpenUpdateModal(e, item.id)}>
            {item.date === TODAY
              ? i18n.t('Today')
              : item.date === TOMORROW
                ? i18n.t('Tomorrow')
                : moment(item.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
          </TableBodyCell>
          <TableBodyCell align="left" onClick={(e) => handleOpenUpdateModal(e, item.id)}>
            <p style={{ marginBottom: 0, font: 'normal 600 14px/19px Nunito Sans, sans-serif' }}>
              {moment(item.start_time, 'HH:mm').format('HH:mm')} - {moment(item.end_time, 'HH:mm').format('HH:mm')}
            </p>
            <p style={{ marginBottom: 0, marginTop: 0, font: 'normal 500 12px/16px Nunito Sans, sans-serif' }}>
              {item.duration ? serviceDuration(item.duration, t('H'), t('Min')) : '-'}
            </p>
          </TableBodyCell>
          <TableBodyCell align="left" onClick={(e) => handleOpenUpdateModal(e, item.id)}>
            {item.customer_name || t('WalkIn')}
          </TableBodyCell>
          {companyGot.is_staff_based && (
            <TableBodyCell align="left" onClick={(e) => handleOpenUpdateModal(e, item.id)}>
              <Box display="flex" alignItems="center">
                {item.staff_avatar ? (
                  <Avatar className={classes.avatarAppointments} alt="avatar" src={item.staff_avatar} />
                ) : (
                  <div className="default-circle">
                    {item.staff_first_name ? (
                      /^[a-zA-Z]*$/.test(item.staff_first_name[0]) ? (
                        getInitials(item.staff_first_name)
                      ) : item.staff_last_name ? (
                        /^[a-zA-Z]*$/.test(item.staff_last_name[0]) ? (
                          getInitials(item.staff_last_name)
                        ) : (
                          <img src={DefaultProfileImage} alt="default" width={32} height={32} />
                        )
                      ) : (
                        <img src={DefaultProfileImage} alt="default" width={32} height={32} />
                      )
                    ) : (
                      <img src={DefaultProfileImage} alt="default" width={32} height={32} />
                    )}
                  </div>
                )}
                {item.staff_first_name
                  ? `${item.staff_first_name} ${
                    item.staff_last_name ? `${item.staff_last_name.charAt(0).toUpperCase()}.` : ''
                  }`
                  : item.individualUser
                    ? item.individualUser.full_name
                    : '-'}
              </Box>
            </TableBodyCell>
          )}
          <TableBodyCell align="left" onClick={(e) => handleOpenUpdateModal(e, item.id)}>
            <Box sx={{ gap: '5px' }} display="flex" alignItems="center">
              {item.service_name && (
                <span
                  style={{
                    color:
                      userSettings.appointment?.appointment_color === 'service'
                        ? item.service_color
                        : getColorsByAppointmentStatus(item.status_name, false),
                  }}
                >
                  •
                </span>
              )}
              {item.service_name || ''}
            </Box>
          </TableBodyCell>
          <TableBodyCell align="left" onClick={(e) => handleOpenUpdateModal(e, item.id)}>
            {generatePrice(item)}
            {/* {item.price ? getServicePrice(item.price.type, item.price.price, companyGot) : '-'} */}
          </TableBodyCell>
          <TableBodyCell align="left">
            <FormControl variant="outlined" size="small" margin="dense">
              {item.status_id === 6 ? (
                <MenuItem className={`menu-item-status appointment-status ${item.status_name}`} value={item.status_id}>
                  {i18n.t(`${capitalize(item.status_name)}`)}
                </MenuItem>
              ) : (
                <SelectStatus
                  classes={classes}
                  selectedItem={item}
                  appointments={appointments}
                  checkStartTimeAppointment={checkStartTimeAppointment}
                  handleAcceptAppointmentSelect={handleAcceptAppointmentSelect}
                  handleChangeAppointmentStatus={handleChangeAppointmentStatus}
                  handleFinishAppointmentSelect={handleFinishAppointmentSelect}
                  handleCancelAppointmentSelect={handleCancelAppointmentSelect}
                  handleNoShowAppointmentSelect={handleNoShowAppointmentSelect}
                />
              )}
            </FormControl>
          </TableBodyCell>
          <TableBodyCell align="left">
            <IconButton onClick={(e) => handleOpenUpdateModal(e, item.id)}>
              <img src={editIcon} alt="edit" />
            </IconButton>
            <IconButton onClick={(e) => showConfirmDelete(e, item.id)}>
              <img src={deleteIcon} alt="delete" />
            </IconButton>
          </TableBodyCell>
        </TableRow>
      ))}
    </TableBody>
  );
}

Body.propTypes = {
  appointments: PropTypes.array.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  checkStartTimeAppointment: PropTypes.func.isRequired,
  handleCancelAppointmentSelect: PropTypes.func.isRequired,
  handleFinishAppointmentSelect: PropTypes.func.isRequired,
  handleChangeAppointmentStatus: PropTypes.func.isRequired,
  handleAcceptAppointmentSelect: PropTypes.func.isRequired,
  handleNoShowAppointmentSelect: PropTypes.func.isRequired,
  showConfirmDelete: PropTypes.func.isRequired,
  handleOpenUpdateModal: PropTypes.func.isRequired,
};

export default Body;
