import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import { getAllCompanyCustomersSelectRequest } from '../../../../../../../redux/customer/actions';
import { emailRegex, phoneNumberRegex } from '../../../../../../../Modules/regexValidations';

function Customer({
  errors, handleChange, appointmentItem,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isGetAllCustomersSelectSuccess, allCompanyCustomersForSelect } = useSelector((state) => state.customers);

  const prevIsGetAllCustomersSelectSuccess = usePrevious(isGetAllCustomersSelectSuccess);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [customerAdd] = useState({
    first_name: '',
    phone_number: '',
    email: '',
  });
  const [customerErrors, setCustomerErrors] = useState({
    email: { isValid: false },
    phone_number: { isRequired: false, isValid: false },
  });
  const [selectedOption, setSelectedOption] = useState({});
  const [loading, setLoading] = useState(true);
  const filter = createFilterOptions();

  useEffect(() => {
    // if (allCompanyCustomersForSelect.length > 0) {
    //   getOptions();
    // } else {
    //
    // }
    dispatch(getAllCompanyCustomersSelectRequest());
  }, []);

  useEffect(() => {
    if (prevIsGetAllCustomersSelectSuccess === false && isGetAllCustomersSelectSuccess) {
      getOptions();
    }
  }, [isGetAllCustomersSelectSuccess]);

  function getOptions() {
    const customerOptions = allCompanyCustomersForSelect.map(({
      id, full_name, phone_number, email,
    }) => ({
      id,
      value: id,
      label: full_name,
      phone_number,
      email,
    }));
    setOptions(customerOptions);

    setLoading(false);
    if (appointmentItem.customer_id) {
      const founded = customerOptions.find((i) => i.id === appointmentItem.customer_id);
      if (founded !== -1) {
        setSelectedOption(founded);
      }
    } else if (appointmentItem.customer_id === null) {
      setSelectedOption({
        id: null,
        value: null,
        label: t('WalkIn'),
      });
    }
  }

  const emailIconAdornment = {
    inputMode: 'string',
    startAdornment: (
      <InputAdornment position="start">
        <MailOutlinedIcon
          fontSize="small"
        />
      </InputAdornment>
    ),
  };

  const phoneIconAdornment = {
    inputMode: 'string',
    pattern: phoneNumberRegex,
    startAdornment: (
      <InputAdornment position="start">
        <PhoneOutlinedIcon
          fontSize="small"
        />
      </InputAdornment>
    ),
  };

  const handleChangeOption = (e, value) => {
    if (value) {
      if (typeof value === 'string') {
        // TODO for now
      } else if (value && value.inputValue) {
        handleChange('customer_name', value.inputValue);
        handleChange('customer_id', '');
      } else {
        setSelectedOption(value);
        handleChange('customer_name', '');
        if (value.label === 'Walk In') {
          handleChange('customer_id', null);
        } else {
          handleChange('customer_id', value.id);
        }
      }
    }
  };

  // const validateForm = () => {
  //   const errorsCopy = { ...customerErrors };
  //   errorsCopy.email.isValid = customerAdd.email.length
  //     ? !emailRegex.test(customerAdd.email.trim())
  //     : false;
  //   // errorsCopy.phone_number.isRequired = phone_number.length === 0;
  //   errorsCopy.phone_number.isValid = customerAdd.phone_number ? customerAdd.phone_number.length > 21 : false;
  //   setCustomerErrors(errorsCopy);

  //   return (
  //     Object.values(errors.phone_number).some((error) => error === true)
  //     || Object.values(errors.email).some((error) => error === true)
  //   );
  // };

  return (
    <div className="input-content">
      <div className="input-title-box">
        <PersonOutlineOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
        <span className="input-title">
          {t('Customer')}
        </span>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {
            appointmentItem.from_widget
              ? (
                <div className="input-content">
                  <div className="info-text">
                    {appointmentItem.customer_name || t('WalkIn')}
                  </div>
                </div>
              )
              : (
                <FormControl
                  fullWidth
                  error={!!errors.customer}
                  size="small"
                  variant="outlined"
                >
                  <Autocomplete
                    // disabled={!!appointmentItem.id}
                    size="small"
                    fullWidth
                    autoHighlight
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    value={selectedOption}
                    onChange={(event, newValue) => handleChangeOption(event, newValue)}
                    // loading={loading.toString()}
                    options={options}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }

                      return option.label || '';
                    }}
                    renderOption={(option) => (
                      <div
                        className={option.label === 'Walk In' ? 'walk-in-option' : 'input-menu'}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label} <span className="customer-option-phone">{option.phone}</span>
                        {option.label === 'Walk In' && (
                          <p className="walk-in-desc">{t('WalkInDesc')}</p>
                        )}
                      </div>
                    )}

                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          label: `Add "${params.inputValue}"`,
                        });
                      }

                      return [{ label: `${t('WalkIn')}`, value: null }, ...filtered];
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading && open ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        label={`${t('Customer')} *`}
                        placeholder={t('ChooseCustomer')}
                        variant="outlined"
                        error={(!!errors.customer)}
                      />
                    )}
                  />
                  { errors.customer && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>
                        {t('Customer') + t('IsRequired')}
                      </FormHelperText>
                    </div>
                  )}
                </FormControl>
              )
          }
        </Grid>
        {!Object.keys(selectedOption).length || selectedOption.id ? (
          <>
            <Grid item xs={6}>
              <TextField
                size="small"
                color="secondary"
                className="input"
                placeholder={t('PhoneNumberOptional')}
                label={t('PhoneNumberOptional')}
                variant="outlined"
                    // error={errors.phone_number.isValid}
                value={selectedOption.phone_number || customerAdd.phone_number}
                    // onChange={(e) => handleChangePhoneNumber(e.target.value)}
                InputProps={phoneIconAdornment}
                disabled={!!selectedOption.id}
              />
              {/* {errors.phone_number.isValid && ( */}
              {/* <FormHelperText error> */}
              {/*   {t('lowercasePhoneNumber') + t('IsNotValid')} */}
              {/* </FormHelperText> */}
              {/* )} */}
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                color="secondary"
                className="input"
                placeholder={t('EmailOptional')}
                label={t('EmailOptional')}
                variant="outlined"
                    // error={errors.email.isValid}
                value={selectedOption.email || customerAdd.email}
                    // onChange={}
                disabled={!!selectedOption.id}
                InputProps={emailIconAdornment}
              />
              {/* {errors.email.isValid && ( */}
              {/*  <FormHelperText error> */}
              {/*    {t('email') + t('IsNotValid')} */}
              {/*  </FormHelperText> */}
              {/* )} */}
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  );
}

Customer.propTypes = {
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  appointmentItem: PropTypes.object.isRequired,
};

export default Customer;
