import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';

const AppDatePicker = ({ selectedDate, onChange, ...props }) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleClickPicker = (e) => {
    e.preventDefault();
    setIsDatePickerOpen(true);
  };

  return (
    <KeyboardDatePicker
      open={isDatePickerOpen}
      disableToolbar
      variant="inline"
      margin="dense"
      InputProps={{ style: { pointerEvents: 'none' } }}
      onClick={handleClickPicker}
      onClose={() => setIsDatePickerOpen(false)}
      style={{ cursor: 'pointer' }}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      keyboardIcon={<CalendarTodayIcon fontSize="small" />}
      {...props}
      format="MM/dd/yyyy"
      value={moment(selectedDate).toDate()}
      onChange={(date) => {
        onChange(date);
        setIsDatePickerOpen(false);
      }}
    />
  );
};

AppDatePicker.propTypes = {
  selectedDate: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disablePast: PropTypes.bool,
};

AppDatePicker.defaultProps = {
  disablePast: false,
};
export default AppDatePicker;
