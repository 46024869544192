import { handleActions } from 'redux-actions';
import {
  storeAppointmentRequest,
  storeAppointmentSuccess,
  storeAppointmentError,
  updateAppointmentRequest,
  updateAppointmentSuccess,
  updateAppointmentError,
  deleteAppointmentRequest,
  deleteAppointmentSuccess,
  deleteAppointmentError,
  getAppointmentsRequest,
  getAppointmentsSuccess,
  getAppointmentsError,
  getAppointmentByIdRequest,
  getAppointmentByIdSuccess,
  getAppointmentByIdError,
  acceptRequest,
  acceptSuccess,
  acceptError,
  cancelRequest,
  cancelSuccess,
  cancelError,
  finishRequest,
  finishSuccess,
  finishError,
  getAllStatusesRequest,
  getAllStatusesSuccess,
  getAllStatusesError,
  getGeneralAppointmentsRequest,
  getGeneralAppointmentsSuccess,
  getGeneralAppointmentsError,
  validAppointmentRequest,
  validAppointmentSuccess,
  validAppointmentError,
  getUserRoleInAppointmentRequest,
  getUserRoleInAppointmentSuccess,
  getUserRoleInAppointmentError,
  filterStaffAppointmentsRequest,
  filterStaffAppointmentsSuccess,
  filterStaffAppointmentsError,
  filterAllAppointmentsRequest,
  filterAllAppointmentsSuccess,
  filterAllAppointmentsError,
  checkAppointmentsByServiceIdRequest,
  checkAppointmentsByServiceIdSuccess,
  checkAppointmentsByServiceIdError,
  checkCompanyDayAppointmentsRequest,
  checkCompanyDayAppointmentsSuccess,
  checkCompanyDayAppointmentsError,
  cancelCompanyDayAppointmentsRequest,
  cancelCompanyDayAppointmentsSuccess,
  cancelCompanyDayAppointmentsError,
  updateAppointmentsReminderRequest,
  updateAppointmentsReminderSuccess,
  updateAppointmentsReminderError,
  updateAppointmentsThankYouMessageRequest,
  updateAppointmentsThankYouMessageSuccess,
  updateAppointmentsThankYouMessageError,
  filterAllIndividualAppointmentsRequest,
  filterAllIndividualAppointmentsSuccess,
  filterAllIndividualAppointmentsError,
  checkIndividualUserIndustryDayAppointmentsRequest,
  checkIndividualUserIndustryDayAppointmentsSuccess,
  checkIndividualUserIndustryDayAppointmentsError,
  cancelIndividualUserIndustryDayAppointmentsRequest,
  cancelIndividualUserIndustryDayAppointmentsSuccess,
  cancelIndividualUserIndustryDayAppointmentsError,
  getIndividualAppointmentsStatisticsRequest,
  getIndividualAppointmentsStatisticsSuccess,
  getIndividualAppointmentsStatisticsError,
  getAppointmentsStatisticsRequest,
  getAppointmentsStatisticsSuccess,
  getAppointmentsStatisticsError,
  getCalendarAppointmentsRequest,
  getCalendarAppointmentsSuccess,
  getCalendarAppointmentsError,
  filterAllMyAppointmentsRequest,
  filterAllMyAppointmentsSuccess,
  createTimeOffRequest,
  createTimeOffSuccess,
  createTimeOffError,
  updateTimeOffRequest,
  updateTimeOffSuccess,
  updateTimeOffError,
  noShowRequest,
  noShowSuccess,
  noShowError,
} from './actions';

const initialState = {
  // Store Appointment
  isStoreAppointmentSuccess: false,
  isStoreAppointmentError: false,
  newAppointment: {},
  storeAppointmentErrors: {},
  storeAppointmentErrorMessage: '',
  // Valid Appointment
  isValidAppointmentSuccess: false,
  isValidAppointmentError: false,
  validAppointment: {},
  validAppointmentErrors: {},
  validAppointmentErrorMessage: '',
  // Update Appointment
  isUpdateAppointmentSuccess: false,
  isUpdateAppointmentError: false,
  updatedAppointment: {},
  updateAppointmentErrorMessage: '',
  updateAppointmentErrors: {},
  // Delete Appointment
  isDeleteAppointmentSuccess: false,
  isDeleteAppointmentError: false,
  deleteAppointmentErrorMessage: '',
  // Get Appointments
  isGetAppointmentsSuccess: false,
  isGetAppointmentsError: false,
  allAppointments: [],
  // Get Appointment By Id
  isGetAppointmentByIdSuccess: false,
  isGetAppointmentByIdError: false,
  returnedAppointment: {},
  getAppointmentByIdErrorMessage: '',
  // Accept Appointment
  isAcceptSuccess: false,
  isAcceptError: false,
  acceptErrorMessage: '',
  // Cancel Appointment
  isCancelSuccess: false,
  isCancelError: false,
  cancelErrorMessage: '',
  // Finish Appointment
  isFinishSuccess: false,
  isFinishError: false,
  finishErrorMessage: '',
  // Get appointment all statuses
  isGetAllStatusesSuccess: false,
  isGetAllStatusesError: false,
  allStatuses: [],
  // Get general appointments
  isGetGeneralAppointmentsSuccess: false,
  isGetGeneralAppointmentsError: false,
  generalAppointments: [],
  // Get User role in appointment
  isGetUserRoleInAppointmentSuccess: false,
  isGetUserRoleInAppointmentError: false,
  userRoleInAppointment: '',
  // Filter Staff Appointments
  isFilterStaffAppointmentsSuccess: false,
  isFilterStaffAppointmentsError: false,
  filteredStaffAppointments: {},
  // Filter All Appointments
  isFilterAllAppointmentsSuccess: false,
  isFilterAllAppointmentsError: false,
  filteredAllAppointments: [],
  filteredAllMyAppointments: [],
  filteredAllMyAppointmentsCount: 0,
  // Check Appointments By Service Id
  isCheckAppointmentByServiceIdSuccess: false,
  isCheckAppointmentByServiceIdError: false,
  appointments: {},
  // Check Day Appointments
  isCheckCompanyDayAppointmentsSuccess: false,
  isCheckCompanyDayAppointmentsError: false,
  companyDayAppointments: {},
  // Cancel Company Day Appointments
  isCancelCompanyDayAppointmentsSuccess: false,
  isCancelCompanyDayAppointmentsError: false,
  cancelCompanyDayAppointmentsErrorMessage: '',
  // Update Appointment Reminder
  isUpdateAppointmentReminderSuccess: false,
  isUpdateAppointmentReminderError: false,
  updateAppointmentReminderErrorMessage: '',
  // Update Appointment Thank You Message
  isUpdateAppointmentThankYouMessageSuccess: false,
  isUpdateAppointmentThankYouMessageError: false,
  isUpdateAppointmentThankYouMessageErrorMessage: '',
  // Filter Individual appointments
  isFilterIndividualAppointmentsSuccess: false,
  isFilterIndividualAppointmentsError: false,
  filteredIndividualAppointments: [],
  // Check Individual User Day Appointments
  isCheckIndividualUserIndustryDayAppointmentsSuccess: false,
  isCheckIndividualUserIndustryDayAppointmentsError: false,
  isCheckIndividualUserIndustryDayAppointmentsErrorMessage: '',
  // Cancel Individual User Day Appointments
  isCancelIndividualUserIndustryDayAppointmentsSuccess: false,
  isCancelIndividualUserIndustryDayAppointmentsError: false,
  cancelIndividualUserIndustryDayAppointmentsErrorMessage: '',
  // Get Individual app statistics
  isGetIndividualAppStatisticsSuccess: false,
  isGetIndividualAppStatisticsError: false,
  individualAppStatistics: {},
  // Get appointment statistics
  isGetAppStatisticsSuccess: false,
  isGetAppStatisticsError: false,
  appointmentsStatistics: {},
  // Get Calendar appointments
  isGetCalendarAppointmentsSuccess: false,
  isGetCalendarAppointmentsError: false,
  getCalendarAppointmentsErrorMessage: '',
  calendarAppointments: [],
  isDeletingAppointment: false,
  timeOffErrorMessage: '',
  isCreatingTimeOff: false,
  isCreatingTimeOffSuccess: false,
  isCreatingTimeOffFailure: false,
  isUpdatingTimeOff: false,
  isUpdatingTimeOffSuccess: false,
  isUpdatingTimeOffError: false,
  isGettingAppointments: false,
  isNoShowSuccess: false,
  isNoShowError: false,
  noShowErrorMessage: false,
};

const reducer = handleActions(
  {
    [storeAppointmentRequest]: (state) => ({
      ...state,
      isStoreAppointmentSuccess: false,
      isStoreAppointmentError: false,
      newAppointment: {},
      storeAppointmentErrors: {},
      storeAppointmentErrorMessage: '',
    }),
    [storeAppointmentSuccess]: (state, { payload }) => ({
      ...state,
      isStoreAppointmentSuccess: true,
      isStoreAppointmentError: false,
      newAppointment: payload.data,
      storeAppointmentErrors: {},
      storeAppointmentErrorMessage: '',
    }),
    [storeAppointmentError]: (state, { payload }) => ({
      ...state,
      isStoreAppointmentSuccess: false,
      isStoreAppointmentError: true,
      newAppointment: {},
      storeAppointmentErrors: payload.errors || {},
      storeAppointmentErrorMessage: payload.message || '',
    }),
    [validAppointmentRequest]: (state) => ({
      ...state,
      isValidAppointmentSuccess: false,
      isValidAppointmentError: false,
      validAppointment: {},
      validAppointmentErrors: {},
      validAppointmentErrorMessage: '',
    }),
    [validAppointmentSuccess]: (state, { payload }) => ({
      ...state,
      isValidAppointmentSuccess: true,
      isValidAppointmentError: false,
      validAppointment: payload.data,
      validAppointmentErrors: {},
      validAppointmentErrorMessage: '',
    }),
    [validAppointmentError]: (state, { payload }) => ({
      ...state,
      isValidAppointmentSuccess: false,
      isValidAppointmentError: true,
      validAppointment: {},
      validAppointmentErrors: payload.errors || {},
      validAppointmentErrorMessage: payload.message || '',
    }),
    [updateAppointmentRequest]: (state) => ({
      ...state,
      isUpdateAppointmentSuccess: false,
      isUpdateAppointmentError: false,
      updatedAppointment: {},
      updateAppointmentErrorMessage: '',
      updateAppointmentErrors: {},
    }),
    [updateAppointmentSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateAppointmentSuccess: true,
      isUpdateAppointmentError: false,
      updatedAppointment: payload.data,
      updateAppointmentErrorMessage: '',
      updateAppointmentErrors: {},
    }),
    [updateAppointmentError]: (state, { payload }) => ({
      ...state,
      isUpdateAppointmentSuccess: false,
      isUpdateAppointmentError: true,
      updatedAppointment: {},
      updateAppointmentErrorMessage: payload.errors || '',
      updateAppointmentErrors: payload.errors || {},
    }),
    [deleteAppointmentRequest]: (state) => ({
      ...state,
      isDeletingAppointment: true,
      isDeleteAppointmentSuccess: false,
      isDeleteAppointmentError: false,
      deleteAppointmentErrorMessage: '',
    }),
    [deleteAppointmentSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingAppointment: false,
      isDeleteAppointmentSuccess: true,
      isDeleteAppointmentError: false,
      deleteAppointmentErrorMessage: '',
      allAppointments: payload.allAppointmentsFiltered,
      filteredAllAppointments: payload.allFilteredAppointments,
      filteredAllMyAppointments: payload.allFilteredAppointments,
    }),
    [deleteAppointmentError]: (state, { payload }) => ({
      ...state,
      isDeletingAppointment: false,
      isDeleteAppointmentSuccess: false,
      isDeleteAppointmentError: true,
      deleteAppointmentErrorMessage: payload.message,
    }),
    [getAppointmentsRequest]: (state) => ({
      ...state,
      isGetAppointmentsSuccess: false,
      isGetAppointmentsError: false,
      allAppointments: [],
    }),
    [getAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAppointmentsSuccess: true,
      isGetAppointmentsError: false,
      allAppointments: payload.data,
    }),
    [getAppointmentsError]: (state) => ({
      ...state,
      isGetAppointmentsSuccess: false,
      isGetAppointmentsError: true,
      allAppointments: [],
    }),
    [getAppointmentByIdRequest]: (state) => ({
      ...state,
      isGetAppointmentByIdSuccess: false,
      isGetAppointmentByIdError: false,
      returnedAppointment: {},
      getAppointmentByIdErrorMessage: '',
    }),
    [getAppointmentByIdSuccess]: (state, { payload }) => ({
      ...state,
      isGetAppointmentByIdSuccess: true,
      isGetAppointmentByIdError: false,
      returnedAppointment: payload.data,
      getAppointmentByIdErrorMessage: '',
    }),
    [getAppointmentByIdError]: (state, { payload }) => ({
      ...state,
      isGetAppointmentByIdSuccess: false,
      isGetAppointmentByIdError: true,
      returnedAppointment: {},
      getAppointmentByIdErrorMessage: payload.message,
    }),
    [acceptRequest]: (state) => ({
      ...state,
      isAcceptSuccess: false,
      isAcceptError: false,
      acceptErrorMessage: '',
    }),
    [acceptSuccess]: (state) => ({
      ...state,
      isAcceptSuccess: true,
      isAcceptError: false,
      acceptErrorMessage: '',
    }),
    [acceptError]: (state, { payload }) => ({
      ...state,
      isAcceptSuccess: false,
      isAcceptError: true,
      acceptErrorMessage: payload.message,
    }),
    [cancelRequest]: (state) => ({
      ...state,
      isCancelSuccess: false,
      isCancelError: false,
      cancelErrorMessage: '',
    }),
    [cancelSuccess]: (state) => ({
      ...state,
      isCancelSuccess: true,
      isCancelError: false,
      cancelErrorMessage: '',
    }),
    [cancelError]: (state, { payload }) => ({
      ...state,
      isCancelSuccess: false,
      isCancelError: true,
      cancelErrorMessage: payload.message,
    }),
    [finishRequest]: (state) => ({
      ...state,
      isFinishSuccess: false,
      isFinishError: false,
      finishErrorMessage: '',
    }),
    [finishSuccess]: (state) => ({
      ...state,
      isFinishSuccess: true,
      isFinishError: false,
      finishErrorMessage: '',
    }),
    [finishError]: (state, { payload }) => ({
      ...state,
      isFinishSuccess: false,
      isFinishError: true,
      finishErrorMessage: payload.message,
    }),
    [noShowRequest]: (state) => ({
      ...state,
      isNoShowSuccess: false,
      isNoShowError: false,
      noShowErrorMessage: '',
    }),
    [noShowSuccess]: (state) => ({
      ...state,
      isNoShowSuccess: true,
      isNoShowError: false,
      noShowErrorMessage: '',
    }),
    [noShowError]: (state) => ({
      ...state,
      isNoShowSuccess: false,
      isNoShowError: true,
      noShowErrorMessage: '',
    }),
    [getAllStatusesRequest]: (state) => ({
      ...state,
      isGetAllStatusesSuccess: false,
      isGetAllStatusesError: false,
      allStatuses: [],
    }),
    [getAllStatusesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllStatusesSuccess: true,
      isGetAllStatusesError: false,
      allStatuses: payload.data,
    }),
    [getAllStatusesError]: (state) => ({
      ...state,
      isGetAllStatusesSuccess: false,
      isGetAllStatusesError: true,
      allStatuses: [],
    }),
    [getGeneralAppointmentsRequest]: (state) => ({
      ...state,
      isGetGeneralAppointmentsSuccess: false,
      isGetGeneralAppointmentsError: false,
      generalAppointments: [],
    }),
    [getGeneralAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      isGetGeneralAppointmentsSuccess: true,
      isGetGeneralAppointmentsError: false,
      generalAppointments: payload.data,
    }),
    [getGeneralAppointmentsError]: (state) => ({
      ...state,
      isGetGeneralAppointmentsSuccess: false,
      isGetGeneralAppointmentsError: true,
      generalAppointments: [],
    }),
    [getUserRoleInAppointmentRequest]: (state) => ({
      ...state,
      isGetUserRoleInAppointmentSuccess: false,
      isGetUserRoleInAppointmentError: false,
      userRoleInAppointment: '',
    }),
    [getUserRoleInAppointmentSuccess]: (state, { payload }) => ({
      ...state,
      isGetUserRoleInAppointmentSuccess: true,
      isGetUserRoleInAppointmentError: false,
      userRoleInAppointment: payload.role,
    }),
    [getUserRoleInAppointmentError]: (state) => ({
      ...state,
      isGetUserRoleInAppointmentSuccess: false,
      isGetUserRoleInAppointmentError: true,
      userRoleInAppointment: '',
    }),
    [filterStaffAppointmentsRequest]: (state) => ({
      ...state,
      isFilterStaffAppointmentsSuccess: false,
      isFilterStaffAppointmentsError: false,
      filteredStaffAppointments: {},
    }),
    [filterStaffAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      isFilterStaffAppointmentsSuccess: true,
      isFilterStaffAppointmentsError: false,
      filteredStaffAppointments: payload.data,
    }),
    [filterStaffAppointmentsError]: (state) => ({
      ...state,
      isFilterStaffAppointmentsSuccess: false,
      isFilterStaffAppointmentsError: false,
      filteredStaffAppointments: {},
    }),
    [filterAllMyAppointmentsRequest]: (state) => ({
      ...state,
      isFilterAllAppointmentsSuccess: false,
      isFilterAllAppointmentsError: false,
      filteredAllMyAppointments: {},
      filteredAllMyAppointmentsCount: 0,
    }),
    [filterAllMyAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      isFilterAllAppointmentsSuccess: true,
      isFilterAllAppointmentsError: false,
      filteredAllMyAppointments: payload.data,
      filteredAllMyAppointmentsCount: payload.total,
    }),
    [filterAllAppointmentsRequest]: (state) => ({
      ...state,
      isFilterAllAppointmentsSuccess: false,
      isFilterAllAppointmentsError: false,
      filteredAllAppointments: [],
    }),
    [filterAllAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      isFilterAllAppointmentsSuccess: true,
      isFilterAllAppointmentsError: false,
      filteredAllAppointments: payload.data,
    }),
    [filterAllAppointmentsError]: (state) => ({
      ...state,
      isFilterAllAppointmentsSuccess: false,
      isFilterAllAppointmentsError: false,
      filteredAllAppointments: [],
      filteredAllMyAppointments: [],
    }),
    [checkAppointmentsByServiceIdRequest]: (state) => ({
      ...state,
      isCheckAppointmentByServiceIdSuccess: false,
      isCheckAppointmentByServiceIdError: false,
      appointments: {},
    }),
    [checkAppointmentsByServiceIdSuccess]: (state, { payload }) => ({
      ...state,
      isCheckAppointmentByServiceIdSuccess: true,
      isCheckAppointmentByServiceIdError: false,
      appointments: payload.data,
    }),
    [checkAppointmentsByServiceIdError]: (state) => ({
      ...state,
      isCheckAppointmentByServiceIdSuccess: false,
      isCheckAppointmentByServiceIdError: true,
      appointments: {},
    }),
    [checkCompanyDayAppointmentsRequest]: (state) => ({
      ...state,
      isCheckCompanyDayAppointmentsSuccess: false,
      isCheckCompanyDayAppointmentsError: false,
      companyDayAppointments: {},
    }),
    [checkCompanyDayAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      isCheckCompanyDayAppointmentsSuccess: true,
      isCheckCompanyDayAppointmentsError: false,
      companyDayAppointments: payload,
    }),
    [checkCompanyDayAppointmentsError]: (state) => ({
      ...state,
      isCheckCompanyDayAppointmentsSuccess: false,
      isCheckCompanyDayAppointmentsError: true,
      companyDayAppointments: {},
    }),
    [cancelCompanyDayAppointmentsRequest]: (state) => ({
      ...state,
      isCancelCompanyDayAppointmentsSuccess: false,
      isCancelCompanyDayAppointmentsError: false,
      cancelCompanyDayAppointmentsErrorMessage: '',
    }),
    [cancelCompanyDayAppointmentsSuccess]: (state) => ({
      ...state,
      isCancelCompanyDayAppointmentsSuccess: true,
      isCancelCompanyDayAppointmentsError: false,
      cancelCompanyDayAppointmentsErrorMessage: '',
    }),
    [cancelCompanyDayAppointmentsError]: (state, { payload }) => ({
      ...state,
      isCancelCompanyDayAppointmentsSuccess: false,
      isCancelCompanyDayAppointmentsError: true,
      cancelCompanyDayAppointmentsErrorMessage: payload.message,
    }),
    [updateAppointmentsReminderRequest]: (state) => ({
      ...state,
      isUpdateAppointmentsReminderSuccess: false,
      isUpdateAppointmentsReminderError: false,
      updateAppointmentsReminderErrorMessage: '',
    }),
    [updateAppointmentsReminderSuccess]: (state) => ({
      ...state,
      isUpdateAppointmentsReminderSuccess: true,
      isUpdateAppointmentsReminderError: false,
      updateAppointmentsReminderErrorMessage: '',
    }),
    [updateAppointmentsReminderError]: (state, { payload }) => ({
      ...state,
      isUpdateAppointmentsReminderSuccess: false,
      isUpdateAppointmentsReminderError: true,
      updateAppointmentsReminderErrorMessage: payload.message,
    }),
    [updateAppointmentsThankYouMessageRequest]: (state) => ({
      ...state,
      isUpdateAppointmentThankYouMessageSuccess: false,
      isUpdateAppointmentThankYouMessageError: false,
      isUpdateAppointmentThankYouMessageErrorMessage: '',
    }),
    [updateAppointmentsThankYouMessageSuccess]: (state) => ({
      ...state,
      isUpdateAppointmentThankYouMessageSuccess: true,
      isUpdateAppointmentThankYouMessageError: false,
      isUpdateAppointmentThankYouMessageErrorMessage: '',
    }),
    [updateAppointmentsThankYouMessageError]: (state, { payload }) => ({
      ...state,
      isUpdateAppointmentThankYouMessageSuccess: false,
      isUpdateAppointmentThankYouMessageError: true,
      isUpdateAppointmentThankYouMessageErrorMessage: payload.message,
    }),
    [filterAllIndividualAppointmentsRequest]: (state) => ({
      ...state,
      isFilterIndividualAppointmentsSuccess: false,
      isFilterIndividualAppointmentsError: false,
      filteredIndividualAppointments: [],
    }),
    [filterAllIndividualAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      isFilterIndividualAppointmentsSuccess: true,
      isFilterIndividualAppointmentsError: false,
      filteredIndividualAppointments: payload.data,
    }),
    [filterAllIndividualAppointmentsError]: (state) => ({
      ...state,
      isFilterIndividualAppointmentsSuccess: false,
      isFilterIndividualAppointmentsError: true,
      filteredIndividualAppointments: [],
    }),
    [checkIndividualUserIndustryDayAppointmentsRequest]: (state) => ({
      ...state,
      isCheckIndividualUserIndustryDayAppointmentsSuccess: false,
      isCheckIndividualUserIndustryDayAppointmentsError: false,
      individualUserIndustryDayAppointments: {},
    }),
    [checkIndividualUserIndustryDayAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      isCheckIndividualUserIndustryDayAppointmentsSuccess: true,
      isCheckIndividualUserIndustryDayAppointmentsError: false,
      individualUserIndustryDayAppointments: payload,
    }),
    [checkIndividualUserIndustryDayAppointmentsError]: (state) => ({
      ...state,
      isCheckIndividualUserIndustryDayAppointmentsSuccess: false,
      isCheckIndividualUserIndustryDayAppointmentsError: true,
      individualUserIndustryDayAppointments: {},
    }),
    [cancelIndividualUserIndustryDayAppointmentsRequest]: (state) => ({
      ...state,
      isCancelIndividualUserIndustryDayAppointmentsSuccess: false,
      isCancelIndividualUserIndustryDayAppointmentsError: false,
      cancelIndividualUserIndustryDayAppointmentsErrorMessage: '',
    }),
    [cancelIndividualUserIndustryDayAppointmentsSuccess]: (state) => ({
      ...state,
      isCancelIndividualUserIndustryDayAppointmentsSuccess: true,
      isCancelIndividualUserIndustryDayAppointmentsError: false,
      cancelIndividualUserIndustryDayAppointmentsErrorMessage: '',
    }),
    [cancelIndividualUserIndustryDayAppointmentsError]: (state, { payload }) => ({
      ...state,
      isCancelIndividualUserIndustryDayAppointmentsSuccess: false,
      isCancelIndividualUserIndustryDayAppointmentsError: true,
      cancelIndividualUserIndustryDayAppointmentsErrorMessage: payload.message,
    }),
    [getIndividualAppointmentsStatisticsRequest]: (state) => ({
      ...state,
      isGetIndividualAppStatisticsSuccess: false,
      isGetIndividualAppStatisticsError: false,
      individualAppStatistics: {},
    }),
    [getIndividualAppointmentsStatisticsSuccess]: (state, { payload }) => ({
      ...state,
      isGetIndividualAppStatisticsSuccess: true,
      isGetIndividualAppStatisticsError: false,
      individualAppStatistics: payload,
    }),
    [getIndividualAppointmentsStatisticsError]: (state) => ({
      ...state,
      isGetIndividualAppStatisticsSuccess: false,
      isGetIndividualAppStatisticsError: true,
      individualAppStatistics: {},
    }),
    [getAppointmentsStatisticsRequest]: (state) => ({
      ...state,
      isGetAppStatisticsSuccess: false,
      isGetAppStatisticsError: false,
      appointmentsStatistics: {},
    }),
    [getAppointmentsStatisticsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAppStatisticsSuccess: true,
      isGetAppStatisticsError: false,
      appointmentsStatistics: payload,
    }),
    [getAppointmentsStatisticsError]: (state) => ({
      ...state,
      isGetAppStatisticsSuccess: false,
      isGetAppStatisticsError: true,
      appointmentsStatistics: {},
    }),
    [getCalendarAppointmentsRequest]: (state) => ({
      ...state,
      isGettingAppointments: true,
      isGetCalendarAppointmentsSuccess: false,
      isGetCalendarAppointmentsError: false,
      getCalendarAppointmentsErrorMessage: '',
      calendarAppointments: [],
    }),
    [getCalendarAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAppointments: false,
      isGetCalendarAppointmentsSuccess: true,
      isGetCalendarAppointmentsError: false,
      getCalendarAppointmentsErrorMessage: '',
      calendarAppointments: payload.data,
    }),
    [getCalendarAppointmentsError]: (state, { payload }) => ({
      ...state,
      isGettingAppointments: false,
      isGetCalendarAppointmentsSuccess: false,
      isGetCalendarAppointmentsError: true,
      getCalendarAppointmentsErrorMessage: payload.message,
      calendarAppointments: [],
    }),
    [createTimeOffRequest]: (state) => ({
      ...state,
      isCreatingTimeOff: true,
      isCreatingTimeOffSuccess: false,
      isCreatingTimeOffFailure: false,
    }),
    [createTimeOffSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingTimeOff: false,
      isCreatingTimeOffSuccess: true,
      calendarAppointments: [payload.data, ...state.calendarAppointments],
    }),
    [createTimeOffError]: (state, { payload }) => ({
      ...state,
      isCreatingTimeOff: false,
      isCreatingTimeOffFailure: true,
      timeOffErrorMessage: payload,
    }),
    [updateTimeOffRequest]: (state) => ({
      ...state,
      isUpdatingTimeOff: true,
      isUpdatingTimeOffSuccess: false,
      isUpdatingTimeOffError: false,
    }),
    [updateTimeOffSuccess]: (state, { payload }) => {
      const appointmentsCopy = [...state.calendarAppointments];
      const index = appointmentsCopy.findIndex((app) => app.id === payload.data.id);
      if (index !== -1) {
        appointmentsCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdatingTimeOff: false,
        isUpdatingTimeOffSuccess: true,
        calendarAppointments: appointmentsCopy,
      };
    },
    [updateTimeOffError]: (state, { payload }) => ({
      ...state,
      isUpdatingTimeOff: false,
      isUpdatingTimeOffError: true,
      timeOffErrorMessage: payload,
    }),
  }, initialState,
);

export default reducer;
